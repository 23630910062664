const filterButton = document.getElementById('object-filter-button');
const typeInput = document.getElementById('type-filter');
const cityInput = document.getElementById('city-filter');
const radiusInput = document.getElementById('radius-filter');
const minRoomsInput = document.getElementById('min-rooms-filter');
const maxRoomsInput = document.getElementById('max-rooms-filter');
const minPriceInput = document.getElementById('min-price-filter');
const maxPriceInput = document.getElementById('max-price-filter');
const filterSection = document.getElementById('filter-section-hero');

const updateRadiusInput = () => {
    const cityFilter = cityInput.value.trim();

    if (cityFilter === '') {
        radiusInput.disabled = true;
        radiusInput.value = '';
    } else {
        radiusInput.disabled = false;
    }
}

cityInput.addEventListener('input', () => {
    updateRadiusInput();
});

filterButton.addEventListener('click', () => {
    const typeFilter = typeInput.value.trim();
    const cityFilter = cityInput.value.trim();
    const radiusFilter = radiusInput.value.trim();
    const minRoomsFilter = minRoomsInput.value.trim();
    const maxRoomsFilter = maxRoomsInput.value.trim();
    const minPriceFilter = minPriceInput.value.trim();
    const maxPriceFilter = maxPriceInput.value.trim();

    const queryParams = new URLSearchParams();
    if (typeFilter !== '') queryParams.set('type', typeFilter);
    if (cityFilter !== '') queryParams.set('city', cityFilter);
    if (radiusFilter !== '') queryParams.set('radius', radiusFilter);
    if (minRoomsFilter !== '') queryParams.set('minRooms', minRoomsFilter);
    if (maxRoomsFilter !== '') queryParams.set('maxRooms', maxRoomsFilter);
    if (minPriceFilter !== '') queryParams.set('minPrice', minPriceFilter);
    if (maxPriceFilter !== '') queryParams.set('maxPrice', maxPriceFilter);

    const queryString = queryParams.toString();
    const targetUrl = filterSection.dataset.rentalObjectUrl;
    window.location.href = targetUrl + (queryString ? `?${queryString}` : '');
});


