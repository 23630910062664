import Dropdown from 'bootstrap/js/src/dropdown';

const dropDownToggles = document.querySelectorAll('.dropdown-toggle');
const dropDownMenus = [];

const windowWidth = window.innerWidth;

if (windowWidth >= 992) {
    dropDownToggles.forEach((item) => {
        const bsDropdown = new Dropdown(item);
        dropDownMenus.push(bsDropdown);

        item.addEventListener('mouseover', (e) => {
            e.preventDefault();
            bsDropdown.toggle();
        });

        item.addEventListener('click', (e) => {
            e.preventDefault();
            location.href = item.getAttribute('href');
        });
    });

    const hideDropdowns = () => {
        dropDownMenus.forEach((item) => {
            item.hide();
        });
    };


    let mouseMoveTimeout = null;
    document.onmousemove = async (e) => {
        if (mouseMoveTimeout) {
            clearTimeout(mouseMoveTimeout)
        }
        mouseMoveTimeout = setTimeout(() => {
            let element = e.target;

            if (element.classList.contains('navbar-nav')
                || element.classList.contains('row')
                || element.classList.contains('container')
                || element.classList.contains('base-layout')
                || element.classList.contains('hero')) {
                hideDropdowns();
            }
        }, 20)
    };
}

