const submitButton = document.getElementById('newsletter-submit-button');
const newsLetterForm = document.getElementById('newsletter-form');

if (submitButton) {
    submitButton.addEventListener('click', (e) => {
        e.preventDefault();

        const emailInput = document.getElementById('newsletter-email');
        let data = new FormData(newsLetterForm);

        const xhr = new XMLHttpRequest();
        xhr.withCredentials = true;

        xhr.addEventListener("readystatechange", function() {
            if (this.readyState === 4) {
                const response = JSON.parse(this.responseText);
                if (response.success) {
                    displaySuccessMessage("Ihr Newsletter-Abonnement wurde berücksichtigt. Wir danken Ihnen.");
                    emailInput.value = '';
                } else {
                    displayErrorMessage("Bei der Übermittlung Ihrer Daten ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.");
                }
            }
        });

        xhr.open("POST", "/actions/newsletter/newsletter/subscribe/");
        xhr.setRequestHeader("Accept", "application/json");
        xhr.send(data);
    });
}

const displaySuccessMessage = (message) => {
    const successDiv = document.getElementById('success-message');
    const errorDiv = document.getElementById('error-message');
    errorDiv.classList.add('d-none');
    successDiv.textContent = message;
    successDiv.classList.remove('d-none');

    // Meldung nach 3 Sekunden ausblenden
    setTimeout(() => {
        successDiv.classList.add('d-none');
    }, 3000);
}

const displayErrorMessage = (message) => {
    const errorDiv = document.getElementById('error-message');
    const successDiv = document.getElementById('success-message');
    successDiv.classList.add('d-none');
    errorDiv.textContent = message;
    errorDiv.classList.remove('d-none');

    // Meldung nach 3 Sekunden ausblenden
    setTimeout(() => {
        errorDiv.classList.add('d-none');
    }, 3000);
}
