var elms = document.getElementsByClassName( 'splide' );

for ( var i = 0; i < elms.length; i++ ) {
    new Splide( elms[ i ], {
        type   : 'loop',
        perPage: 2,
        focus: 0,
        breakpoints: {
            756: {
                perPage: 1,
            },
        },
    }).mount();
}